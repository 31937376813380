const theme = {

    // 1- On appel les fonctions nécessaires au chargement
    init: function(){
        theme.setThemetoColor();
        theme.checkChangeOnThemeSelection();
    },

    // 2- On initialise le thème couleur par défaut
    setThemetoColor: function() {

        let string = localStorage.getItem('bwTheme'); 
/*         let printerIcon = document.querySelector('.bi-printer');
        let displayIcon = document.querySelector('.bi-display'); */
        
        // On regarde en local si on a stocké une valeur qui nous intéresse
        if (string === 'true' || string === 'false') {
            if (string === 'false') {
                document.querySelector('#selectedTheme').checked = true;
            } 
            else {
                document.querySelector('#selectedTheme').checked = false;
            }
            // Dans ce cas on retourne la valeur
            theme.activateBwTheme(string);
        }

        // On renvoie une valeur par défaut si on ne trouve rien d'exploitable
        else {
            document.querySelector('#selectedTheme').checked = true;
/*             displayIcon.classList.remove('d-block');
            printerIcon.classList.add('d-none'); */

            theme.activateBwTheme('false');
        }
    },


    // 3- On stocke le choix par défault ou utilisateur en local
    activateBwTheme: function (string) {
        localStorage.setItem('bwTheme', string);
        theme.retrieveTheme();
    },

    // 4- On récupère le choix par défaut ou utilisateur pour le répercuter dans l'UI
    retrieveTheme: function() {
        
        let bodyEl = document.querySelector('body');
/*         let printerIcon = document.querySelector('.bi-printer');
        let displayIcon = document.querySelector('.bi-display'); */
        let activeBwTheme = localStorage.getItem('bwTheme');
        
        if (activeBwTheme === 'false') {

/*             printerIcon.classList.remove('d-block');
            printerIcon.classList.add('d-none');

            displayIcon.classList.add('d-block');
            displayIcon.classList.remove('d-none'); */


            if (bodyEl.classList.contains('bw-theme')) {
                bodyEl.classList.remove('bw-theme');     
            }
        } 
        else {

/*             printerIcon.classList.remove('d-none');
            printerIcon.classList.add('d-block');

            displayIcon.classList.remove('d-block');
            displayIcon.classList.add('d-none'); */

            bodyEl.classList.add('bw-theme');
        }
    },


    // 5- On écoute les changements de l'utilisateur 
    checkChangeOnThemeSelection: function () {
        
        let selectedTheme = document.querySelector('#selectedTheme');

        selectedTheme.addEventListener('change', (e) => {
            console.log('changement: ' + e.currentTarget.checked);

            if(e.currentTarget.checked == true) {
                theme.activateBwTheme(false);
            } else {
                theme.activateBwTheme(true);
            }
        })
    },
}

document.addEventListener('DOMContentLoaded', theme.init);








