const collabSwiper = new Swiper(".collabSwiper", {
    spaceBetween: 10,
    paginationClickable: true,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      // when window width is >= 320px
      576: {
        slidesPerView: 1,
        spaceBetween: 20
      },
      // when window width is >= 480px
      668: {
        slidesPerView: 1.5,
        spaceBetween: 20
      },
      // when window width is >= 640px
      992: {
        slidesPerView: 2.5,
        spaceBetween: 30,
        slidesPerGroup: 2
      },
      // when window width is >= 640px
      1200: {
        slidesPerView: 3,
        spaceBetween: 30,
        slidesPerGroup: 3
      }
    }
});

const mostViewedSwiper = new Swiper(".mostViewedSwiper", {
    spaceBetween: 10,
    paginationClickable: true,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-ms-next',
      prevEl: '.swiper-button-ms-prev',
    },
    breakpoints: {
      // when window width is >= 320px
      576: {
        slidesPerView: 1,
        spaceBetween: 20
      },
      // when window width is >= 480px
      668: {
        slidesPerView: 1.5,
        spaceBetween: 20
      },
      // when window width is >= 640px
      992: {
        slidesPerView: 2.5,
        spaceBetween: 30,
        slidesPerGroup: 2
      },
      // when window width is >= 640px
      1200: {
        slidesPerView: 3,
        spaceBetween: 30,
        slidesPerGroup: 3
      }
    }
});

