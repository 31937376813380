const menuToggleLinks = document.querySelectorAll('.menu-toggle li.mainnav-item');

menuToggleLinks.forEach((item) => {
    item.addEventListener('pointerenter', (e) => {
        let currentLink = e.currentTarget;
        let hiddenBorder = currentLink.querySelector('.underlined');
        hiddenBorder.style.display = 'block';
    })
    item.addEventListener('pointerleave', (e) => {
        let currentLink = e.currentTarget;
        let hiddenBorder = currentLink.querySelector('.underlined');
        hiddenBorder.style.display = 'none';
    })
})