// On regarde si on est en local ou en prod
const protocol = window.location.protocol;
const baseUrl = window.location.host;
const url = protocol + "//" + baseUrl;
console.log(url);

let page='';
	
// On récupère le bouton load results et on prévoit d'autres déclencheurs
const loadresultsAction = document.querySelectorAll('.loadresults');

// On récupère le bouton loadresults pour l'afficher ou le masquer suivant la pagination
const loadresultsButton = document.querySelector('#load-results');

// On initialise les valeurs de recherche par défaut
let currentPage = document.querySelector('input[name="currentPage"]');
let useCase = document.querySelector('input[name="useCase"]');
let years = document.querySelector('input[name="years"]');
let searchName = document.querySelector('input[name="searchName"]');
let postType = document.querySelector('input[name="post_type"]');

// On récupère le noeud d'affichage des actus
let resultsItem = document.querySelector('#search-results');

// Helper : affichage du nombre de résultats trouvés
function areResultsDisplayed(){
    const countResults = document.querySelector('#count-results');
    if(countResults.textContent.includes('undefined')) {
        countResults.textContent = '0 résultat';
        loadresultsButton.style.opacity = 0;
    } else {
        loadresultsButton.style.opacity = 1;
    }

    if(resultsItem.firstChild && resultsItem.textContent == 'undefined') {
        resultsItem.textContent = '';
    }
}

// On gère le traitement des valeurs en fonction des événements
let radiosEl = document.querySelectorAll('input[name="year"]');

// Année mise à jour
radiosEl.forEach((radio) => {
    radio.addEventListener('change', (e) => {
        console.log(e.currentTarget.parentElement.querySelector('label'));
        radiosEl.forEach(radio => radio.parentElement.querySelector('label').classList.remove('label-white'));
        radiosEl.forEach(radio => radio.parentElement.querySelector('label').classList.remove('active'));
        e.currentTarget.parentElement.querySelector('label').classList.add('label-white');
        e.currentTarget.parentElement.querySelector('label').classList.add('active');

        document.querySelector('input[name="years"]').value = e.currentTarget.value;
        document.querySelector('input[name="useCase"]').value = 'init';
        document.querySelector('input[name="currentPage"]').value = 1;
        fetchNews();
    })
})


// Saisie d'une chaine de caractère 
let searchInput = document.querySelector('#search-field');
if (searchInput) {
    let timer;
    searchInput.addEventListener('input', (e) => {
        document.querySelector('input[name="useCase"]').value = 'init';
        document.querySelector('input[name="currentPage"]').value = 1;
        document.querySelector('input[name="searchName"]').value = e.target.value;
    
        resultsItem.innerHTML = `
            <div class="row justify-content-center">
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        `;
    
        clearTimeout(timer);
        timer = setTimeout(() => {
            fetchNews();
        }, 400)
    });
}

// Gestion du chargement de plus de résultats
loadresultsAction.forEach( (index) => {
	index.addEventListener('click', (e) => {
        currentPage = document.querySelector('input[name="currentPage"]').value;
        currentPage = Number(currentPage) + 1;
        document.querySelector('input[name="currentPage"]').value = currentPage;
        document.querySelector('input[name="useCase"]').value = 'add';
		fetchNews();
	});
});


// Recherche
function fetchNews() {
    searchName = document.querySelector('input[name="searchName"]').value;
    years = document.querySelector('input[name="years"]').value;
    postType = document.querySelector('input[name="post_type"]').value;
    useCase = document.querySelector('input[name="useCase"]').value;
    currentPage = document.querySelector('input[name="currentPage"]').value;
    console.log(years);
	fetch(
		url + '/wp-admin/admin-ajax.php', 
		{
			method: 'POST',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Cache-Control': 'no-cache',
				'Accept': 'application/json'
			},
			body: new URLSearchParams({
				action : 'load_results',
				years : years,
				paged : currentPage,
				postType : postType,
                searchName: searchName
			})
		}
	)
	.then(response => response.json())
	.then(data => {

        // Affichage du nombre de résultats
        const countResults = document.querySelector('#count-results');
        
        // gestion du pluriel
        let displayResults = data.count > 1 ? ' résultats' : ' résultat';
        countResults.textContent =  data.count + displayResults;

		// Dois-je réafficher le bouton Loadresults
		function displayLoadResults() {

			if (currentPage >= data.max) {
				loadresultsButton.classList.remove('d-block');
				loadresultsButton.classList.add('d-none');
			}

			else {
				loadresultsButton.classList.remove('d-none');
				loadresultsButton.classList.add('d-block');
			}
		}

		// Cas on clique sur le bouton loadresults
		if (useCase === 'add') {
            resultsItem.insertAdjacentHTML('beforeEnd', data.html);
			displayLoadResults();
            areResultsDisplayed();
		}

		// Cas où on relance la requête avec une catégorie
		else if (useCase === 'init') {
			resultsItem.innerHTML = data.html;
			displayLoadResults();
            areResultsDisplayed();
		}

	})
	.catch(error => {
		console.log(error);
	})
}





