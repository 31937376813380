// Hamburger menu
const bodyEl = document.querySelector('body');
const buttonHamburger = document.querySelector('.button-hamburger');

if (buttonHamburger) {
    buttonHamburger.addEventListener('click', () => {
        const isOpened = buttonHamburger.getAttribute('aria-expanded');
        if (isOpened === 'false') {
            buttonHamburger.setAttribute('aria-expanded', true);
            bodyEl.classList.add('overflow-hidden');
        } else {
            buttonHamburger.setAttribute('aria-expanded', false);
            bodyEl.classList.remove('overflow-hidden');
        }
    })

// Mobile UI & behaviour

const t1 = new TimelineMax({paused: true});

const animateOpenNav = () => {
    t1.to("#nav-container", 0.2, 
        {
            autoAlpha: 1,
            delay: 0.35,
        }
    );

    t1.to(".site-logo", 0.2, 
        {
            color: "#fff"
        }, 
        "-=0.1"
    );

    t1.from(".flex > div", 0.4, 
        {
            opacity: 0,
            y: 10,
            stagger: {
                amount: 0.04,
            },
        }
    );

    t1.to(".nav-link > a", .15, 
        {
            top: 0,
            ease: "power2.out",
            stagger: {
                amount: 0,
            },
        }, 
        "-=0.1"
    );

    t1.from(".nav-footer", 0, 
        {
            opacity: 0
        }
    ).reverse();
    
}

const openNav = () => {

    animateOpenNav();

    const navBtn = document.querySelector("#menu-toggle-btn");

    navBtn.addEventListener('click', () => {
        navBtn.classList.toggle("active");
        t1.reversed(!t1.reversed());
    });
};

openNav();

}





