// Setup
const registerNl = document.querySelector('#basic-addon2');
let inputEmail = document.querySelector('#inputEmail');
console.log(inputEmail);

if (inputEmail) {
    inputEmail.addEventListener('input', (e) => {
        let value = document.querySelector('#inputEmail').value;
        ValidateEmail(value);
    })

    // Validation de l'input
    function ValidateEmail(input) {

        const validRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        let timer;
        const waitTime = 400;
        timer = setTimeout(() => {
            // Ok
            if (input.match(validRegex)) {
                // On enable le bouton
                registerNl.disabled = false;
                console.log('match');

                if (registerNl.classList.contains('invalidated')) {
                    registerNl.classList.remove('invalidated');
                }
                if (!registerNl.classList.contains('validated')) {
                    registerNl.classList.add('validated');
                    
                }
                registerNl.style.opacity = 1;
                registerNl.style.cursor = 'pointer';
                return true;
            } 
            // Ko
            else {

                // On disable le bouton
                registerNl.disabled = true;
                console.log('no match');

                if (inputEmail.classList.contains('validated')) {
                    inputEmail.classList.remove('validated');
                }
                if (!inputEmail.classList.contains('invalidated')) {
                    inputEmail.classList.add('invalidated');
                }
                registerNl.style.opacity = .75;
                return false;
            }
        }, waitTime)

    }

    registerNl.addEventListener('click', () => {
        // user input
        let inputEmail = document.querySelector('#inputEmail');
        
        // champ caché
        let modalEmail = document.querySelector('#EMAIL');
        modalEmail.value = inputEmail.value;

        // champ de validation
        let givenEmail = document.querySelector('#givenEmail');
        givenEmail.textContent = inputEmail.value;
        
    })


}


