// JS
import "../scss/style.scss";
import * as bootstrap from 'bootstrap';
import '../../../node_modules/swiper/swiper.scss';

import '../../../node_modules/swiper/swiper-bundle.js';
import './theme';
import './nav';
import './mobilenav';
import './swiper';
import './loadresults';
import './newsletter';

// images for css
import img from './../img/x-lg.svg';

console.log('app');

// Tooltips activation
const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))


// Qui sommes nous 
if (window.location.href.includes('qui-sommes-nous')) {
    const linkCollapseMore = document.querySelector('#collaposeMore');
    const seeMoreSvg = document.querySelector('#seeMore');
    const seeLessSvg = document.querySelector('#seeLess');

    linkCollapseMore.addEventListener('click', (e) => {
        if (e.currentTarget.getAttribute('aria-expanded') === 'true') {
            e.currentTarget.textContent = 'Voir moins';
            seeMoreSvg.classList.remove('d-block');
            seeMoreSvg.classList.add('d-none');
            seeLessSvg.classList.remove('d-none');
            seeLessSvg.classList.add('d-block');
        } else {
            e.currentTarget.textContent = 'Voir plus';
            seeLessSvg.classList.remove('d-block');
            seeLessSvg.classList.add('d-none');
            seeMoreSvg.classList.remove('d-none');
            seeMoreSvg.classList.add('d-block');
        }
    })
}